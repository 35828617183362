import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TipoObraComponent } from './components/page/tipoobra/tipoobra.component';
import { PreevaluacionAddComponent } from './components/page/preevaluacionexpediente/preevaluacion-add/preevaluacion-add.component';
 
const appRoutes: Routes = [
  { 
    path: 'page', 
    loadChildren: 'src/app/components/page/page.module#PageModule', 
    //canLoad: [AuthGuard]  //Only users auth
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules, useHash: true})],    
  
exports: [RouterModule]
})
export class AppRoutingModule { }