import { Injectable, OnInit } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { AuthService } from './../../services/auth.service';
import { User } from './../../models/user';
import { Data } from './../shared/data/Data';
import { AppData } from './../../AppData';


@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

    pass: String;
    username: string;
    data = new AppData();
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const users: Array<User> = Data.USUARIOS;

        return of(null).pipe(mergeMap(() => {
            // authenticate - public

            if (request.url.endsWith('/users/signin') && request.method === 'POST') {

                for (let i = 0; i < users.length; i++) {
                    if (users[i].username === request.body.username && users[i].correlativo_id === request.body.password) {
                        this.data._flguser = 1;
                        // console.log('Username correcto ' + this.data._flguser);
                        return ok({
                            id: request.body.id, //user.id,
                            name: request.body.name,
                            username: request.body.username,
                            email: request.body.email,
                            id_user_estudiante: request.body.id_user_estudiante,
                            correlativo_id: request.body.correlativo_id,
                            token: `fake-jwt-token`
                        });
                    } else {
                        //this.data._loading = false;
                        this.data._flguser = 0;
                        //console.log('Username or password is incorrect' + this.data._flguser);
                    }
                }
            }
            // pass through any requests not handled above
            return next.handle(request);
        }))
            // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(materialize())
            .pipe(delay(100))
            .pipe(dematerialize());

        // private helper functions

        function ok(body) {
            return of(new HttpResponse({ status: 200, body }));
        }

        /*function unauthorised() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }*/

        function error(message) {
            return throwError({ status: 400, error: { message } });
        }
    }
}

export let fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};