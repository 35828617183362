import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppData } from './../../AppData';
import { Data } from './../shared/data/Data';  

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  hide = true;
  submitted = false;
  returnUrl: string;
  error = '';
  data = new AppData(); 

  constructor(public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.data._loading = true; 
    this.createForm();
    
    this.authService.getAllUsers().subscribe(res => {
      
      Data.USUARIOS = res;
      
    });
  }


  ngOnInit() {
    
    this.createForm();
    // reset login status
    this.authService.logout();
    
  }

  createForm() {
    this.loginFormGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form field
  login() {

    this.authService.login(this.loginFormGroup.get('username').value, 
    this.loginFormGroup.get('password').value).subscribe(() => {
      if (this.authService.isLoggedIn) {
        let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : 'page';//'/admin';
        let navigationExtras: NavigationExtras = {
          queryParamsHandling: 'preserve',
          preserveFragment: true
        };
        // Redirect the user
        this.router.navigateByUrl(redirect, navigationExtras);

      }
    });

    if (this.data._flguser === 1) {
      this.data._loading = true;
    } else {
      this.data._loading = false;
    }

  }

  logout() {
    localStorage.removeItem('currentUser');
    this.authService.logout();
  }
}