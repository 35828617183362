export class AppConstanst {

    //-------------------------URIS-------------------------// 
    //public static get baseURL(): string { return "http://localhost:8080"; } 
    public static baseURL = "https://apps.sigmaversion.net/becas"; //<--- PRINCIPAL (AMAZON), COMENTAR PARA PRUEBAS
    //public static baseURL = "http://localhost:8080/becas"; //<---local, AMAZON DEV
    //public static baseURL = "http://becas.sigmaversion.net"; //<---AMAZON PROD

    //public static baseIntegradorURL = "http://200.48.164.49:4254/integradorBecas";
    //public static baseIntegradorURL = "http://localhost:9000/"; // PRUEBAS NO ES LLAMADO DESDE NINGUN LUGAR AL PARECER
 
    public static integradorCHAMPSURL = "http://190.223.41.154:2710/integradorBecas";
    public static integradorSANJOCURL = "http://200.41.85.173:4250/integradorBecas"; 
    public static integradorBARINAURL = "http://45.231.74.92:4254/integradorBecas";
    public static integradorSANJOHURL = "http://190.116.38.122:4254/integradorBecas";
    public static integradorCHAMPCURL = "http://177.91.250.116:4254/integradorBecas";
    public static integradorSANLUIURL = "http://191.98.186.152:4255/integradorBecas";
    public static integradorSTAROSURL = "http://190.117.59.5:4254/integradorBecas";
    //* IP's publicas de colegios que faltan agregar  */
    public static integradorSANJOSURL = "http://ingresar IP's puerto/integradorBecas";
  
    //PAGE
    public static get tipoobraURL(): string { return this.baseURL + "/page/tipoobra"; }
    public static get obraURL(): string { return this.baseURL + "/page/obra"; }

    //FAMILIAR DEL ESTUDIANTE
    public static get familiardelestudianteUrl(): string { return this.baseURL + "/page/familiardelestudiante"; }
    public static get familiar_estudianteUrl(): string { return this.baseURL + "/page/familiardelestudiante/estudiante"; }
    public static get familiares_porCodigoUrl(): string { return this.baseURL + "/page/familiardelestudiante/familiares/porid"; }

    public static get familiar_estudianteParienteUrl(): string { return this.baseURL + "/page/familiardelestudiante/estudiante/parientes"; }
    public static get familiardelestudiantesUrl(): string { return this.baseURL + "/page/familiardelestudiantes"; }
    public static get parientes_estudianteUrl(): string { return this.baseURL + "/page/familiardelestudiante/estudiante/familiares"; }

    //DECLARACION JURADA
    public static get declaracionjuradaURL(): string { return this.baseURL + "/page/declaracionjuradafamiliar"; }
    public static get djEstudianteURL(): string { return this.baseURL + "/page/declaracionjuradafamiliar/estudiante"; }
    public static get djMaxDeclaracionFamiliarJuradaURL(): string { return this.baseURL + "/page/declaracionjuradafamiliar/maxDeclaracionjuradafamiliar_id"; }

    // ESTUDIANTE
    public static get estudianteURL(): string { return this.baseURL + "/page/estudiante"; }
    //public static get estudianteObraURL(): string { return this.baseURL + "/page/estudiante/all"; }
    public static get estadoEvaluacionestudianteURL(): string { return this.baseURL + "/page/estudiante/estadoevaluacion"; }
    public static get emailURL(): string { return this.baseURL + "/page/estudiante/email" }
    public static get estudianteListURL(): string { return this.baseURL + "/page/estudiante/list"; }

    //ESTUDIANTE INTEGRADOR
    public static get estudianteintegradorURL(): string { return "/page/estudiante_integrador" };
    public static get estudiantebecaintegradorURL(): string { return "/page/estudiante_integradorbeca" };
    public static get InsertarEstudianteBecaURL(): string { return "/page/insertabecas" };
    public static get familiardelestudiantesintegradorUrl(): string { return "/page/familiarestudiantes_integrador"; }

    // EVALUACION
    public static get evaluacionURL(): string { return this.baseURL + "/page/evaluacion"; }
    public static get evaluacionDeclaracionjurdafamiliarURL(): string { return this.baseURL + "/page/evaluacion/declaracionjuradafamiliar"; }
    public static get evaluacionpropuestabecaURL(): string { return this.baseURL + "/page/evaluacion/propuestabeca"; }
    public static get evaluacionEstudianteURL(): string { return this.baseURL + "/page/evaluacion/estudiante"; }

    //EVALUACIONASPECTO
    public static get evaluacionaspectoURL(): string { return this.baseURL + "/page/evaluacionaspecto"; }
    public static get evaluacionvaloracionURL(): string { return this.baseURL + "/page/evaluacionaspecto/evaluacion"; }
    public static get evaluacionaspectofilterURL(): string { return this.baseURL + "/page/evaluacionaspecto/evaluacion/aspecto"; }

    //ASPECTO
    public static get aspectoURL(): string { return this.baseURL + "/page/aspecto"; }

    //BECA
    public static get becaURL(): string { return this.baseURL + "/page/beca"; }

    //CONCEPTO
    public static get conceptoURL(): string { return this.baseURL + "/page/concepto"; }

    //EMAIL
    public static get bandejaemailURL(): string { return this.baseURL + "/page/bandejaemail"; }
    // public static get bandejaemailURL(): string {return "http://localhost:8090/api/v1/email/state"; }

    //DISTRITO
    public static get distritoURL(): string { return this.baseURL + "/page/distrito"; }

    //ASIGNACIONBECA
    public static get asignacionbecainsertURL(): string { return this.baseURL + "/page/asignacionbeca/evaluacion"; }
    public static get asignacionbecaByIdURL(): string { return this.baseURL + "/page/asignacionbeca/evaluacion/ById"; }
    public static get asignacionbecaByNombreURL(): string { return this.baseURL + "/page/asignacionbeca/ByNombre"; }

    //TABLAS INDEPENDIENTES
    public static get tipoparentescoURL(): string { return this.baseURL + "/page/tipoparentesco"; }
    public static get estadocivilURL(): string { return this.baseURL + "/page/estadocivil"; }
    public static get tipodocumentoURL(): string { return this.baseURL + "/page/tipodocumento"; }
    public static get paisURL(): string { return this.baseURL + "/page/pais"; }
    public static get gradoacademicoURL(): string { return this.baseURL + "/page/gradoacademico"; }
    public static get nivelacademicoURL(): string { return this.baseURL + "/page/nivelacademico"; }
    public static get periodoURL(): string { return this.baseURL + "/page/periodo"; }
    public static get valoracionURL(): string { return this.baseURL + "/page/valoracion"; }

    public static get tiporesponsablepagoURL(): string { return this.baseURL + "/page/tiporesponsablepago"; }
    public static get tipoviaURL(): string { return this.baseURL + "/page/tipovia"; }
    public static get tipoestatusestudianteURL(): string { return this.baseURL + "/page/tipoestatusestudiante"; }
    public static get tipomodopagoURL(): string { return this.baseURL + "/page/tipomodopago"; }

    //ASIGNACIONBECA
    public static get asignacionbecaURL(): string { return this.baseURL + "/page/asignacionbeca"; }
    //RECONSIDERACIONBECA
    public static get reconsideracionBeca(): string { return this.baseURL + "/page/reconsideracionbeca"; }
    //IMPRESION CARTAS
    public static get impresioncartasURL(): string { return this.baseURL + "/page/impresioncartas"; }

    //SEGURIDAD
    public static get authURL(): string { return this.baseURL + "/api/auth" }

    //SEGURIDAD
    public static get seguridadURL(): string { return this.baseURL + "/page/seguridad" }
 
    //PROCESO
    public static get tipoprocesoURL(): string { return this.baseURL + "/page/tipoproceso"; }
    public static get procesoURL(): string { return this.baseURL + "/page/proceso"; }

    //ASPECTO
    public static get graficoURL(): string { return this.baseURL + "/page/grafico/estrategico"; }

    //MENU
    public static get menuURL(): string { return this.baseURL + "/page/menu"; }
    public static get submenuURL(): string { return this.baseURL + "/page/submenu"; }
    public static get submenuMenuURL(): string { return this.baseURL + "/page/submenu/menu"; }
    public static get apiURL(): string { return this.baseURL + "/api/auth/signup" };

}