export class AppData {

    public salariopadre: number;
    public salariomadre:  number;
    public salarioaportante:  number;
    public honorariosprofesionalespadre:  number;
    public honorariosprofesionalesmadre:  number;
    public honorariosprofesionalesaportante:  number;
    public pensionjubilacionpadre:  number;
    public pensionjubilacionmadre:  number;
    public pensionjubilacionaportante:  number;
    public negociopadre:  number;
    public negociomadre:  number;
    public negocioaportante:  number;
    public rentainmueblespadre:  number;
    public rentainmueblesmadre:  number;
    public rentainmueblesaportante:  number;
    public inversionpadre:  number;
    public inversionmadre:  number;
    public inversionaportante:  number;
    public otroingresopadre:  number;
    public otroingresomadre:  number;
    public otroingresoaportante:  number;

    public alquiler:  number;
    public mantenimiento:  number;
    public agua:  number;
    public luz:  number;
    public telefono:  number;
    public celular:  number;
    public cable:  number;
    public otrosvivienda:  number;
    public colegio:  number;
    public universidad:  number;
    public instituto:  number;
    public academia:  number;
    public otroseducacion:  number;
    public movilidadpadre:  number;
    public movilidadmadre:  number;
    public movilidadhijos:  number;
    public movilidadotros:  number;
    public mensual:  number;
    public otrosalimentacion:  number;
    public planmedico:  number;
    public medicamentos:  number;
    public otrossalud:  number;
    public vigilancia:  number;
    public serviciodomestico:  number;
    public higieneylimpieza:  number;
    public tarjetacredito:  number;
    public prestamopersonal:  number;
    public prestamovehicular:  number;
    public hipoteca:  number;
    
    public _obra_id: string;
    public _id:  number;
    public _loading: boolean;
    public _flguser:  number;
}