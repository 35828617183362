import { Injectable }       from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot,
         RouterStateSnapshot, CanActivateChild,
         NavigationExtras, CanLoad, Route } from '@angular/router';
import { AuthService } from './../services/auth.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
    /*let url: string = state.url;
    return this.checkLogin(url);*/
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log("entro las canActivateChild - Route:"+ route+ "   state: "+state);
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    console.log("Url::::::::::::::::::: "+route.path);
    let url = `/${route.path}`;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn) { return true; }
    // (Almacena el intento de URL para redireccionar) Store the attempted URL for redirecting 
    this.authService.redirectUrl = url;
    // (Crear un ID de sesión ficticio) Create a dummy session id
    let sessionId = 123456789;
    // (Establece nuestro objeto de navegación extras) Set our navigation extras object
    // (que contiene nuestros parámetros y fragmentos de consulta global) that contains our global query params and fragment
    let navigationExtras: NavigationExtras = {
      queryParams: { 'session_id': sessionId },
      fragment: 'anchor'
    };
    // (Vaya a la página de inicio de sesión con extras) Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
  }
}