import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { User } from "../models/user";
import { AppConstanst } from './../../config/app-constanst';
import { tap, delay } from 'rxjs/operators';
import { SignUpForm } from './../models/signUpForm';
import { catchError } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';
import { Data } from './../components/shared/data/Data';


@Injectable({
  providedIn: "root"
})
export class AuthService {
  isLoggedIn = false;
  data: Data;
  // store the URL so we can redirect after logging in
  redirectUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  URL = AppConstanst.authURL;

  constructor(
    private http: HttpClient) {
    
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    
  }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });


  registerUser(user: SignUpForm) {
    const url_api = AppConstanst.apiURL;
    return this.http
      .post<SignUpForm>(
        url_api,
        user,
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }
  //--------------------REGITRO - USER----------------------//
  //----------------------LOGIN - USER---------------------//
  login(username: string, password: string): Observable<any> {
    const url_api = `${this.URL}/users/signin`;
    

    return this.http
      .post<User>(url_api, { username, password })
      .pipe(map(data => { 
        if (data) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(data));
          //console.log('IMPRESION LOGIN' + JSON.stringify(data));
          this.currentUserSubject.next(data);
          //this.isLoggedIn = true;
        }
        return data;
      }), tap(val => this.isLoggedIn = true));
  }
  //----------------------LOGOUT - USER---------------------//
  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  //----------------------USUARIO ACTUAL--------------------//
  getCurrentUser(): User {
    let user_string = localStorage.getItem("currentUser");
    if (!isNullOrUndefined(user_string)) {
      let user: User = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  setUser(user: User): void {
    let user_string = JSON.stringify(user);
    localStorage.setItem("currentUser", user_string);
  }

  setToken(token): void {
    localStorage.setItem("accessToken", token);
  }

  getToken() {
    return localStorage.getItem("accessToken");
  }


  getUsuarioById(id_user_estudiante: number): Observable<any> {
    const url = `${this.URL}/${id_user_estudiante}`; // DELETE api/heroes/42
    return this.http.get<User>(url)
  }

  getAllUsers(): Observable<any> {
    const url = `${this.URL}/users`;
    return this.http.get<User[]>(url).pipe(
      catchError((error: any) => {
        console.error(error);
        return of();
      }),
    );
    
  }

  public get getUsers(): Observable<any> {
    const url = `${this.URL}/users`;
    return this.http.get<any>(url); //.pipe(map((res: Response) => res.json()))
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  findUsernameById(username: string): Observable<any> {
    const url = `${this.URL}/usuario/${username}`; // DELETE api/heroes/42
    //console.log('Consulta Rutas Usuario');
    //console.log(url);
    return this.http.get<User>(url)
  }

  //---------------- ELIMINAR - DELETE -------------------//
 /* deleteUsersById(id: any): Observable<any> {
    console.log(' id usuario ::::::' + id);
    const url = `${this.URL}/usuario/id/${id}`;
    return this.http.get<void>(url)
  }*/
}